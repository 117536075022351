@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-RegularItalic';
    src: url('../../public/font/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../public/font/Gilroy-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    width: 5px;
    height: 0;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #5AA0E1;
    border-radius: 3px;
}


.theme-switcher {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    max-width: 42px;
    width: 42px;
    max-height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: 0;
    justify-content: center;
    transition: max-width, width 400ms ease-in-out;
    border: none;
    overflow: hidden;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #282C31;
}

.dark .theme-switcher {
    background-color: #DEE2E6;
}

.theme-switcher:hover {
    max-width: 500px;
    width: 16ch;
}

.theme-switcher svg {
    font-size: 24px;
    color: #fff;
}

.dark .theme-switcher svg, .dark .theme-switcher span {
    color: #000;
}

.theme-switcher span {
    position: absolute;
    color: #fff;
    left: 42px;
    width: 10ch;
    font-weight: 200;
}

.theme-switcher .spacer {
    flex: 1;
}
